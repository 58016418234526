export const tableHeader = [
  {
    name: "",
    field: "comment",
    align: "left",
    sorting: false,
  },
  {
    name: "Company Name",
    field: "name",
    align: "left",
    sorting: true,
  },
  {
    name: "Trade",
    field: "field",
    align: "left",
    sorting: false,
  },
  {
    name: "Office Number",
    field: "contactNumber",
    align: "left",
    sorting: false,
  },
  {
    name: "Bid Email",
    field: "email",
    align: "left",
    sorting: false,
  },
  {
    name: "Address",
    field: "address",
    align: "left",
    sorting: true,
  },
  {
    name: "Actions",
    field: "actions",
    align: "left",
    sorting: false,
  },
];
