import { FormFields } from "models/interfaces";
import { useEffect, useMemo, useState } from "react";
import { basicInfoFormInitialState } from "./AddApplicantFormStates";
import { useParams } from "react-router-dom";
import { useTitle } from "utils/UseTitle";
import title from "global/constants/TitleConstants";
import { editApplicant, getApplicantById } from "../ApplicantService";
import {
  getPhoneNumberWithCountryCode,
  handleErrorMessage,
  isTruthy,
  openSuccessNotification,
} from "helpers/methods";
import {
  Avatar,
  Button,
  Card,
  Container,
  Divider,
  Grid,
  Link,
  Stack,
  StepIconProps,
  SvgIcon,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import CenteredAuthenticatedLayout from "layouts/CenteredAuthenticated/CenteredAuthenticatedLayout";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import BasicInfo from "./components/BasicInfo";
import { validateForBasicInfoForm } from "./AddApplicantTypesAndValidation";
import history from "utils/history";
import CustomLoader from "global/components/CustomLoader/CustomLoader";
import viewpaths from "global/constants/ViewPathConstants";
import { deepCopy } from "utils/deep-copy";

interface CustomProps {
  location?: any;
}

const AddApplicant1 = (props: CustomProps) => {
  const { id } = useParams<{ id: string }>();
  const documentData: any = history.location?.state;

  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [applicantData, setApplicantData] = useState<any>();
  const [basicInfoForm, setBasicInfoForm] = useState<FormFields>(
    basicInfoFormInitialState(applicantData)
  );
  const [activeStep, setActiveStep] = useState<number>(
    documentData?.activeStep ? documentData?.activeStep : 0
  );

  const theme = useTheme();
  const isDesktop = useMediaQuery(theme.breakpoints.up("md"));
  useTitle(
    id
      ? title.addPrefixToTitle(
          applicantData?.name ?? "",
          title.EDIT_SUBCONTRACTOR
        )
      : title.ADD_SUBCONTRACTOR
  );

  useEffect(() => {
    if (id) {
      getCandidate();
    }
  }, [id]);

  const getCandidate = async () => {
    try {
      setIsLoading(true);
      const response = await getApplicantById(id);
      setBasicInfoForm(basicInfoFormInitialState(response));
      setApplicantData(response);
      setIsLoading(false);
    } catch (error: any) {
      setIsLoading(false);
      handleErrorMessage(error);
    }
  };

  const handleValidation = () => {
    const { isValid, errors }: { isValid: boolean; errors: any } =
      validateForBasicInfoForm(basicInfoForm);
    setBasicInfoForm({ ...errors });
    return isValid;
  };

  const handleSave = async () => {
    if (handleValidation()) {
      const poc = deepCopy(basicInfoForm.poc.value);
      const workingLocation = deepCopy(basicInfoForm.workingLocation.value);
      const xlProject = deepCopy(basicInfoForm.xlProject.value);
      if (basicInfoForm.poc.value.length !== 0) {
        // @ts-ignore
        for (const [index, element] of basicInfoForm.poc.value.entries()) {
          if (
            element.name === "" &&
            element.email === "" &&
            element.designation === "" &&
            ((typeof element.phone === "string" && element.phone === "+1 ") ||
              isTruthy(element.phone.value))
          ) {
            poc.splice(index, 1);
          }
          element.phone =
            typeof element.phone === "string"
              ? getPhoneNumberWithCountryCode(element.phone)
              : element.phone;
        }
      }
      if (basicInfoForm.workingLocation.value.length !== 0) {
        // @ts-ignore
        // prettier-ignore
        for (const [index,element] of basicInfoForm.workingLocation.value.entries()) {
        if (
          element.city === "" &&
          element.state === "" &&
          element.zip.length === 0
        ) {
          workingLocation.splice(index, 1);
        }
      }
      }
      if (basicInfoForm.xlProject.value.length !== 0) {
        // @ts-ignore
        // prettier-ignore
        for (const [index,element] of basicInfoForm.xlProject.value.entries()) {
          if (
            element.number === "" &&
            element.name === "" &&
            element.location === "" &&
            element.ownerName === ""
          ) {
            xlProject.splice(index, 1);
          }
        }
      }
      let reqObj = {
        id: basicInfoForm.id.value,
        name: basicInfoForm.name.value,
        field: basicInfoForm.field.value,
        phone: getPhoneNumberWithCountryCode(basicInfoForm.phone.value),
        email: basicInfoForm.email.value,
        address: basicInfoForm.address.value,
        poc,
        workingLocation,
        xlProject,
      };
      if (id) {
        reqObj = { ...reqObj };
      }
      try {
        setIsLoading(true);
        await editApplicant(reqObj);
        openSuccessNotification(
          id
            ? "Applicant updated successfully!"
            : "Applicant added successfully!"
        );
        history.goBack();
        setIsLoading(false);
      } catch (error: any) {
        setIsLoading(false);
        handleErrorMessage(error);
      }
    }
  };

  const steps = useMemo(() => {
    return [
      {
        label: "Basic Details",
        content: (
          <Stack direction="column" spacing={1}>
            <BasicInfo
              formFields={basicInfoForm}
              setFormFields={setBasicInfoForm}
            />
            <Grid container spacing={2}>
              {/* <Grid item>
                <Button variant="contained" size="small" onClick={handleNext}>
                  Continue
                </Button>
              </Grid> */}
            </Grid>
          </Stack>
        ),
      },
    ];
  }, [
    // handleBack,
    // handleNext,
    // handleComplete,
    basicInfoForm,
    // preferencesForm,
  ]);

  const getStepIcon = (props: StepIconProps) => {
    return (
      <Avatar
        sx={{
          height: 26,
          width: 26,
          border:
            props.active || props.completed
              ? "1px solid #f25822"
              : "1px solid #999999",
          color: props.active || props.completed ? "#FFFFFF" : "#999999",
          backgroundColor:
            props.active || props.completed ? "#f25822" : "#FFFFFF",
          cursor: "pointer",
        }}
      >
        {props.icon}
      </Avatar>
    );
  };

  const getAddEditApplicantBody = () => {
    return (
      <Card>
        <Container maxWidth="xl">
          <Stack direction="row" justifyContent="space-between">
            <Stack direction="row" p={{ lg: 4, xs: 3 }} width="100%">
              <Stack direction="column" alignItems="center" spacing={1}>
                <Divider
                  orientation="vertical"
                  sx={{
                    height: "100%",
                    borderStyle: "dashed",
                    borderColor: "#f25822",
                    // backgroundColor: "#85C935",
                  }}
                />
              </Stack>
              <Grid container>
                <Grid item xl={12} xs={12} ml={2}>
                  {steps[activeStep].content}
                </Grid>
              </Grid>
            </Stack>
          </Stack>
        </Container>
      </Card>
    );
  };

  const getApplicantPageHeader = () => {
    return (
      <Stack spacing={2}>
        <Link
          color="text.primary"
          href={viewpaths.subcontractorViewPath}
          sx={{
            alignItems: "center",
            display: "inline-flex",
          }}
          onClick={(e) => {
            e.preventDefault();
            history.goBack();
          }}
          underline="hover"
        >
          <SvgIcon sx={{ mr: 1 }}>
            <ArrowBackIcon htmlColor="#5A5A5A" />
          </SvgIcon>
          <Typography variant="subtitle1" sx={{ color: "#5A5A5A" }}>
            Subcontractor
          </Typography>
        </Link>
        <Stack direction="row" justifyContent="space-between">
          <Typography variant="h4">
            {id ? "Edit Subcontractor" : "Add Subcontractor"}
          </Typography>
          <Button
            id="add_update_applicant"
            variant="contained"
            size="small"
            onClick={() => handleSave()}
            disabled={!id && activeStep !== steps.length - 1 ? true : false}
          >
            {id ? "Update" : "Save"}
          </Button>
        </Stack>
      </Stack>
    );
  };

  return (
    <CenteredAuthenticatedLayout>
      <CustomLoader isLoading={isLoading} />
      <Stack spacing={4}>
        {getApplicantPageHeader()}
        {getAddEditApplicantBody()}
      </Stack>
    </CenteredAuthenticatedLayout>
  );
};

export default AddApplicant1;
