import type { ChangeEvent, MouseEvent } from "react";
import Box from "@mui/material/Box";
import Checkbox from "@mui/material/Checkbox";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { Skeleton, TableContainer, TableSortLabel } from "@mui/material";
import NoTableDataImage from "assets/images/NoDataImage.svg";
import CustomPagination from "./CustomPagination";

interface CustomerListTableProps {
  headers?: any[];
  count?: number;
  rows?: any[];
  onDeselectAll?: () => void;
  onDeselectOne?: (id: string) => void;
  onPageChange?: (
    event: MouseEvent<HTMLButtonElement> | null,
    newPage: number
  ) => void;
  onRowsPerPageChange?: Function;
  onRowsSelect?: (event: MouseEvent<HTMLInputElement>) => void;
  onSelectAll?: () => void;
  onSelectOne?: (id: string) => void;
  page?: number;
  rowsPerPage?: number;
  selected?: string[];
  variant?: "primary" | "secondary";
  showPagination?: boolean;
  showJumpToPageNumber?: boolean;
  jumpToPageNumber?: number;
  setJumpToPageNumber?: Function;
  handleOnChangePageInput?: Function;
  caption?: JSX.Element | null;
  showCheckbox?: boolean;
  onRowHover?: (index: number) => void;
  onRowLeave?: () => void;
  isLoading: boolean;
  handleSort?: (field: string) => void;
  sortConfig?: { field: string; order: Order; entity: string }[];
}

type Order = "asc" | "desc";

const CustomTable = (props: CustomerListTableProps) => {
  const {
    headers = [],
    count = 0,
    rows = [],
    onDeselectAll,
    onDeselectOne,
    onPageChange = () => {},
    onRowsPerPageChange,
    onSelectAll,
    onSelectOne,
    page = 0,
    rowsPerPage = 0,
    selected = [],
    onRowsSelect,
    onRowHover = () => {},
    onRowLeave,
  } = props;
  const selectedSome = selected.length > 0 && selected.length < rows.length;
  const selectedAll = rows.length > 0 && selected.length === rows.length;
  const variant = props.variant ?? "primary";
  const showPagination = props.showPagination ?? true;

  const getHeadersData = () => {
    if (!props.showCheckbox) {
      return headers;
    }
    const newHeaders = [
      {
        name: (
          <Checkbox
            checked={selectedAll}
            indeterminate={selectedSome}
            onChange={(event) => {
              if (event.target.checked) {
                onSelectAll?.();
              } else {
                onDeselectAll?.();
              }
            }}
          />
        ),
        field: "checkbox",
        align: "left",
        sorting: false,
      },
      ...headers,
    ];
    return newHeaders;
  };

  const getRowsData = () => {
    if (!props.showCheckbox) {
      return rows;
    }
    const newRows = rows.map((row) => {
      const isSelected = selected.includes(row.rowId);
      return {
        isSelected,
        checkbox: (
          <Checkbox
            checked={isSelected}
            onChange={(event: ChangeEvent<HTMLInputElement>): void => {
              if (event.target.checked) {
                onSelectOne?.(row.rowId);
              } else {
                onDeselectOne?.(row.rowId);
              }
            }}
            value={isSelected}
          />
        ),
        ...row,
      };
    });
    return newRows;
  };

  const getHeaders = () => {
    const headers = getHeadersData();
    return (
      <TableHead>
        <TableRow>
          {headers.map((column, index) => (
            <TableCell align={column["align"]} key={index}>
              {/* {column.name} */}
              {column.sorting ? (
                <TableSortLabel
                  active={
                    props.sortConfig &&
                    props.sortConfig.some((item) => item.field === column.field)
                  }
                  direction={
                    (props.sortConfig &&
                      props.sortConfig.find(
                        (item) => item.field === column.field
                      )?.order) ??
                    "asc"
                  }
                  onClick={() =>
                    props.handleSort && props.handleSort(column.field)
                  }
                  // sx={classes.tableSortLabel}
                >
                  {column.name}
                  {props.sortConfig &&
                  props.sortConfig.some(
                    (item) => item.field === column.field
                  ) ? (
                    <Box component="span">
                      {/* {order === "desc"
                      ? "sorted descending"
                      : "sorted ascending"} */}
                    </Box>
                  ) : null}
                </TableSortLabel>
              ) : (
                column.name
              )}
            </TableCell>
          ))}
        </TableRow>
      </TableHead>
    );
  };

  const getRowData = (row: any) => {
    const headers = getHeadersData();
    return headers.map((column, index) => (
      <TableCell align={column["align"]} key={index}>
        {row[column["field"]]}
      </TableCell>
    ));
  };

  const TableRowsLoader = (headers: any[], rowsNum: number) => {
    return [...Array(rowsNum)].map((row, index) => (
      <TableRow key={index}>
        {headers.map((header, headerIndex) => (
          <TableCell key={headerIndex}>
            <Skeleton animation="wave" variant="text" height={25} />
          </TableCell>
        ))}
      </TableRow>
    ));
  };

  const getRowOnClickHandler = (row: any) => {
    let handleRowClick = onRowsSelect
      ? {
          onClick: () => onRowsSelect(row),
        }
      : {};
    return handleRowClick;
  };

  return (
    <Box sx={{ position: "relative" }}>
      <TableContainer
        sx={{ borderRadius: variant === "primary" ? "0px" : "20px" }}
      >
        <Table sx={{ minWidth: 700 }}>
          <caption>{props.caption}</caption>
          {getHeaders()}
          <TableBody>
            {!props.isLoading && rows?.length <= 0 && (
              <TableCell colSpan={99} sx={{ borderBottom: "none" }}>
                <Box textAlign="center" margin="50px">
                  <Box
                    component="img"
                    src={NoTableDataImage}
                    overflow="auto"
                    height="180px"
                    width="100%"
                  />
                </Box>
              </TableCell>
            )}
            {props.isLoading
              ? TableRowsLoader(getHeadersData(), 5)
              : getRowsData().map((row, index) => {
                  return (
                    <TableRow
                      hover
                      key={index}
                      selected={row.isSelected}
                      onMouseEnter={() => onRowHover(index)}
                      onMouseLeave={onRowLeave}
                    >
                      {getRowData(row)}
                    </TableRow>
                  );
                })}
          </TableBody>
        </Table>
      </TableContainer>
      {variant === "primary" && showPagination && rows?.length ? (
        <CustomPagination
          count={count}
          onPageChange={onPageChange}
          onRowsPerPageChange={onRowsPerPageChange}
          page={page}
          jumpToPageNumber={props.jumpToPageNumber}
          handleOnChangePageInput={props.handleOnChangePageInput}
          showJumpToPageNumber={props.showJumpToPageNumber}
          setJumpToPageNumber={props.setJumpToPageNumber}
          rowsPerPage={rowsPerPage}
          rowsPerPageOptions={[5, 10, 25, 50, 75, 100]}
          shape="rounded"
        />
      ) : null}
    </Box>
  );
};

export default CustomTable;
