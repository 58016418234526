import { Box, Card, CardHeader, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import viewCandidateStyles from "../ViewApplicant.styles";
import { Subcontractor } from "../../applicantTypes";
import { formattedPhoneNumber, isTruthy } from "helpers/methods";
import CustomTable from "global/components/CustomTable/CustomTable";

interface CustomProps {
  candidateData: Subcontractor;
  loader: boolean;
  setLoader: Function;
}

const commentTableHeaders = [
  {
    name: "Name",
    field: "name",
    align: "left",
  },
  {
    name: "Designation",
    field: "designation",
    align: "left",
  },
  {
    name: "Phone",
    field: "phone",
    align: "left",
  },
];

const Poc = (props: CustomProps) => {
  const [hoveredRowIndex, setHoveredRowIndex] = useState<number | null>(null);
  const [formFields, setFormFields] = useState<any>({
    value: props.candidateData?.poc ?? [],
    error: "",
  });

  useEffect(() => {
    isTruthy(props.candidateData!) &&
      setFormFields({
        value: props.candidateData?.poc ?? formFields.value,
        error: "",
      });
  }, [props.candidateData!]);

  const handleRowHover = (index: number) => {
    setHoveredRowIndex(index);
  };

  const handleRowLeave = () => {
    setHoveredRowIndex(null);
  };

  const convertCommentsToTable = (data: any) => {
    return data.map((comment: any, index: number) => {
      return {
        name: (
          <Box sx={viewCandidateStyles.labelText}>
            <Typography sx={viewCandidateStyles.valueText}>
              {comment.name}
            </Typography>
            <Typography variant="body2">{comment.email}</Typography>
          </Box>
        ),
        designation: (
          <Box sx={viewCandidateStyles.labelText}>
            <Typography sx={viewCandidateStyles.valueText}>
              {comment.designation}
            </Typography>
          </Box>
        ),
        phone: (
          <Box sx={viewCandidateStyles.labelText}>
            <Typography sx={viewCandidateStyles.valueText}>
              {comment?.phone?.value &&
                `+${formattedPhoneNumber(
                  `${comment?.phone?.countryCode} ${comment?.phone?.value}`
                )}`}
            </Typography>
          </Box>
        ),
      };
    });
  };

  return (
    <>
      <Card>
        <CardHeader title="POC" />
        <Box mt={1}>
          <CustomTable
            headers={commentTableHeaders}
            rows={convertCommentsToTable(formFields.value)}
            showPagination={false}
            onRowHover={handleRowHover}
            onRowLeave={handleRowLeave}
            isLoading={props.loader}
          />
        </Box>
      </Card>
    </>
  );
};

export default Poc;
